import React, { useState } from 'react';
import HomeNavbar from "./HomeNavbar";

const Contact = ({user}) => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [name, setName] = useState('');
    const [subject, setSubject] = useState('');

    const handleMailtoLinkClick = () => {
        const mailtoLink = `mailto:jason@gmail.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(`Name: ${name}\nEmail: ${email}\n\nMessage:\n${message}`)}`;
        window.location.href = mailtoLink;
    };

    return (
        <>
            <HomeNavbar user={user} boldItem={"contact"}/>
            <div className="container py-5">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="card shadow-sm border-0">
                            <div className="card-body p-5">
                                <h1 className="display-4 text-center mb-4">Get in Touch</h1>
                                <p className="text-center text-muted mb-5">
                                    Have a question or want to learn more? We'd love to hear from you.
                                </p>
                                
                                <form className="needs-validation" noValidate>
                                    <div className="row g-4">
                                        <div className="col-md-6">
                                            <div className="form-floating mb-3">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="name"
                                                    placeholder="Your Name"
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                    required
                                                />
                                                <label htmlFor="name">Your Name</label>
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-6">
                                            <div className="form-floating mb-3">
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    id="email"
                                                    placeholder="name@example.com"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    required
                                                />
                                                <label htmlFor="email">Email Address</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-floating mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="subject"
                                            placeholder="Subject"
                                            value={subject}
                                            onChange={(e) => setSubject(e.target.value)}
                                            required
                                        />
                                        <label htmlFor="subject">Subject</label>
                                    </div>

                                    <div className="form-floating mb-4">
                                        <textarea
                                            className="form-control"
                                            placeholder="Your message"
                                            id="message"
                                            style={{ height: '150px' }}
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            required
                                        ></textarea>
                                        <label htmlFor="message">Message</label>
                                    </div>

                                    <div className="d-grid">
                                        <button 
                                            type="button" 
                                            className="btn btn-primary btn-lg"
                                            onClick={handleMailtoLinkClick}
                                        >
                                            Send Message
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        
                        <div className="row g-4 mt-5">
                            <div className="col-md-4">
                                <div className="text-center">
                                    <i className="bi bi-envelope-fill fs-2 text-primary mb-3"></i>
                                    <h5>Email</h5>
                                    <p className="text-muted">jason@gmail.com</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="text-center">
                                    <i className="bi bi-clock-fill fs-2 text-primary mb-3"></i>
                                    <h5>Business Hours</h5>
                                    <p className="text-muted">Mon - Fri, 9:00 - 18:00</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="text-center">
                                    <i className="bi bi-lightning-fill fs-2 text-primary mb-3"></i>
                                    <h5>Quick Response</h5>
                                    <p className="text-muted">Within 24 hours</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contact;
