import React from 'react';
import { FaLock } from 'react-icons/fa';
import './styles/NoSubscription.css';

const NoSubscription = () => {
    return (
        <div className="no-subscription-container">
            <div className="no-subscription-card">
                <div className="no-subscription-icon">
                    <FaLock size={40} />
                </div>
                <h2 className="no-subscription-title">Premium Feature</h2>
                <p className="no-subscription-text">
                    This feature requires an active subscription to OFD.
                    <br />
                    Unlock all features and start your journey today!
                </p>
                <div className="no-subscription-actions">
                    <a href="/subscription" className="no-subscription-button primary">
                        View Subscription Plans
                    </a>
                    <a href="/contact" className="no-subscription-button secondary">
                        Contact Support
                    </a>
                </div>
            </div>
        </div>
    );
};

export default NoSubscription;