import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './styles/AccessDenied.css';

const AccessDenied = () => {
    const navigate = useNavigate();

    return (
        <div className="access-denied-container">
            <div className="access-denied-card">
                <div className="access-denied-icon">
                    <FaExclamationTriangle size={40} />
                </div>
                <h2 className="access-denied-title">Access Denied</h2>
                <p className="access-denied-text">
                    You don't have permission to access this page.
                    <br />
                    Please contact an administrator if you think this is a mistake.
                </p>
                <div className="access-denied-actions">
                    <button 
                        onClick={() => navigate('/')} 
                        className="access-denied-button primary"
                    >
                        Return to Home
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AccessDenied;
