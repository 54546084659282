import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import firebase from "../firebaseConfig";
import toast, { toastConfig } from 'react-simple-toasts';
import 'react-simple-toasts/dist/theme/dark.css';
import DashBoardNavBar from "./DashboardNavbar";
import NoSubscription from "./NoSubscription";
import '../styles/account.css';
import { FiUser, FiCalendar, FiMoon, FiLock, FiTrash2, FiClock, FiMail } from 'react-icons/fi';

toastConfig({ theme: 'dark' });

const Account = ({ user }) => {
    const [userData, setUserData] = useState(null);
    const [planValidity, setPlanValidity] = useState(0);
    const [eraseDataModal, setEraseDataModal] = useState(false);
    const [nightPerson, setNightPerson] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkPlanValidity = async () => {
            if (!user) {
                navigate('/login');
                return;
            }

            try {
                const docRef = firebase.firestore().collection('users').doc(user.uid);
                const docSnapshot = await docRef.get();
                
                if (docSnapshot.exists) {
                    const data = docSnapshot.data();
                    const endSubscriptionDate = data.endSubscriptionDate?.toDate();
                    setNightPerson(data.nightPerson);
                    setUserData(data);

                    if (endSubscriptionDate > new Date()) {
                        setPlanValidity(2);
                        if (!data.tutorialCompleted) {
                            navigate('/welcome');
                        }
                    } else {
                        setPlanValidity(1);
                    }
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
                toast('Error loading account data');
            }
        };

        checkPlanValidity();
    }, [user, navigate]);

    const formatDate = (timestamp) => {
        if (timestamp?.seconds) {
            const date = new Date(timestamp.seconds * 1000);
            return date.toLocaleDateString('en-US', { 
                year: 'numeric', 
                month: 'long', 
                day: 'numeric' 
            });
        }
        return 'Not available';
    };

    const handleForgotPassword = async () => {
        try {
            await firebase.auth().sendPasswordResetEmail(userData.email);
            toast('Password reset email sent. Please check your inbox.');
        } catch (error) {
            toast('Error sending password reset email');
        }
    };

    const handleNightPersonChange = async () => {
        try {
            if (user) {
                const docRef = firebase.firestore().collection('users').doc(user.uid);
                await docRef.update({ nightPerson: !nightPerson });
                setNightPerson(!nightPerson);
                toast('Night mode preference updated');
            }
        } catch (error) {
            console.error("Error updating night mode:", error);
            toast('Error updating preference');
        }
    };

    const handleResetData = async () => {
        try {
            const newData = {
                Goals_1: {
                    experience_1: '',
                    globalView_1: '',
                    intentions_1: '',
                    in10x_1: '',
                    globalView_2: '',
                    intentions_2: '',
                    globalView_3: '',
                    intentions_3: '',
                    processGoals_4: '',
                    values_5: '',
                },
                SingleTasking_2: {
                    priorities_action_list: { columns: [
                        { id: 1, title: 'TO-DO', cards: [] },
                        { id: 2, title: 'DOING', cards: [] },
                        { id: 3, title: 'DONE', cards: [] }
                    ]},
                    secondary_action_list: { columns: [
                        { id: 1, title: 'TO-DO', cards: [] },
                        { id: 2, title: 'DOING', cards: [] },
                        { id: 3, title: 'DONE', cards: [] }
                    ]},
                    easiest_action_list: { columns: [
                        { id: 1, title: 'TO-DO', cards: [] },
                        { id: 2, title: 'DOING', cards: [] },
                        { id: 3, title: 'DONE', cards: [] }
                    ]},
                    clustering_action_list: { columns: [
                        { id: 1, title: 'TO-DO', cards: [] },
                        { id: 2, title: 'DOING', cards: [] },
                        { id: 3, title: 'DONE', cards: [] }
                    ]},
                    delegating_action_list: { columns: [
                        { id: 1, title: 'TO-DO', cards: [] },
                        { id: 2, title: 'DOING', cards: [] },
                        { id: 3, title: 'DONE', cards: [] }
                    ]}
                },
                FlowTrigger_3: {
                    curiosity_1: '',
                    concentration_2: '',
                    skill_3: '',
                    feedback_4: '',
                    richEnv_5: '',
                    deepEnv_6: '',
                    highEnv_7: '',
                    creativity_8: '',
                    triggers_9: ''
                },
                FlowRoutines_4: [{}, {}, {}, {}, {}],
                Release_5: {
                    release_1: '',
                    release_2: '',
                    recoveryEnd_3: '',
                    restOff_4: '',
                    restVacation_5: ''
                },
                DistractionsInterruptions_6: [{}, {}, {}, {}, {}],
                StickingPlan_7: [{}, {}, {}, {}, {}],
                Logistics_8: [],
                TimeManagement_9: [],
                CausesSymptoms_10: [{}, {}, {}, {}, {}],
                FlowTrackerData: []
            };

            await firebase.firestore().collection('users').doc(user.uid).update(newData);
            setEraseDataModal(false);
            toast('Data reset successfully');
            window.location.reload();
        } catch (error) {
            console.error("Error resetting data:", error);
            toast('Error resetting data');
        }
    };

    if (planValidity === 1) {
        return <NoSubscription />;
    }

    return (
        <div className="account-page">
            <DashBoardNavBar userName={userData?.username} />
            <div className="account-container">
                <div className="account-header">
                    <h1 className="account-title">Account Settings</h1>
                    <p className="account-subtitle">Manage your account preferences and settings</p>
                </div>

                <div className="account-grid">
                    <div className="account-section">
                        <h2 className="section-title">
                            <FiUser /> Profile Information
                        </h2>
                        <div className="info-grid">
                            <div className="info-item">
                                <div className="info-label">User ID</div>
                                <div className="info-value">{user?.uid}</div>
                            </div>
                            <div className="info-item">
                                <div className="info-label">Username</div>
                                <div className="info-value">{userData?.username || 'Not set'}</div>
                            </div>
                            <div className="info-item">
                                <div className="info-label"><FiMail /> Email</div>
                                <div className="info-value">{userData?.email}</div>
                            </div>
                            <div className="info-item">
                                <div className="info-label"><FiCalendar /> Account Created</div>
                                <div className="info-value">{formatDate(userData?.accountCreationDate)}</div>
                            </div>
                            <div className="info-item highlight">
                                <div className="info-label"><FiClock /> Subscription Expires</div>
                                <div className="info-value accent">{formatDate(userData?.endSubscriptionDate)}</div>
                            </div>
                        </div>
                    </div>

                    <div className="account-section">
                        <h2 className="section-title">
                            <FiMoon /> Night Mode Preference
                        </h2>
                        <div className="preference-item">
                            <div className="preference-content">
                                <div className="preference-title">Night Person Mode</div>
                                <div className="preference-description">
                                    Enable for accurate time tracking of after-midnight workflows. 
                                    This helps calculate correct durations for tasks spanning across midnight 
                                    (e.g., 23:00 to 02:00 = 3 hours).
                                </div>
                            </div>
                            <label className="toggle-switch">
                                <input
                                    type="checkbox"
                                    checked={nightPerson}
                                    onChange={handleNightPersonChange}
                                />
                                <span className="toggle-slider"></span>
                            </label>
                        </div>
                    </div>

                    <div className="account-section">
                        <h2 className="section-title">
                            <FiLock /> Security
                        </h2>
                        <div className="security-item">
                            <div className="security-content">
                                <div className="security-title">Password</div>
                                <div className="security-value">••••••••</div>
                            </div>
                            <button className="action-button primary-button" onClick={handleForgotPassword}>
                                Reset Password
                            </button>
                        </div>
                    </div>

                    <div className="account-section danger-zone">
                        <h2 className="section-title">
                            <FiTrash2 /> Data Management
                        </h2>
                        <div className="danger-content">
                            <div className="danger-description">
                                <strong>Warning:</strong> Resetting your data will permanently erase all your:
                                <ul>
                                    <li>Flow state records</li>
                                    <li>Task management data</li>
                                    <li>Personal preferences</li>
                                    <li>Flow tracker history</li>
                                </ul>
                                This action cannot be undone.
                            </div>
                            <button className="action-button danger-button" onClick={() => setEraseDataModal(true)}>
                                Reset All Data
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {eraseDataModal && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title">Confirm Data Reset</h2>
                            <button
                                className="modal-close"
                                onClick={() => setEraseDataModal(false)}
                                aria-label="Close"
                            >
                                ×
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="warning-icon">⚠️</div>
                            <p className="modal-message">
                                Are you absolutely sure you want to reset all your data? 
                                This will permanently erase everything and cannot be undone.
                            </p>
                            <div className="modal-actions">
                                <button 
                                    className="action-button secondary-button" 
                                    onClick={() => setEraseDataModal(false)}
                                >
                                    Cancel
                                </button>
                                <button 
                                    className="action-button danger-button" 
                                    onClick={handleResetData}
                                >
                                    Yes, Reset Everything
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Account;
