import React, {useEffect, useState} from 'react';
import "../styles/HomePage.css"
import {BsGraphUp, BsPieChart, BsPersonBadge} from "react-icons/bs";
import { FaFacebook, FaTwitter, FaInstagram, FaArrowRight } from 'react-icons/fa';
import { Alert } from 'react-bootstrap';
import HomeNavbar from "./HomeNavbar";
import backgroundImage from '../img/waveBG.jpg';
import screen1 from '../img/dailyRoutine.png';
import screen2 from '../img/FlowCHarts2.png';
import screen3 from '../img/GoalsImg.png';
import screen4 from '../img/TimeManagmentImg.png';
import phone from '../img/OFD Mobile.png';
import { Link, useNavigate } from 'react-router-dom';

const CookieBanner = () => {
    const [showBanner, setShowBanner] = useState(true);

    useEffect(() => {
        const cookieAccepted = localStorage.getItem('cookieAccepted');
        if (cookieAccepted) {
            setShowBanner(false);
        }
    }, []);

    const acceptCookies = () => {
        localStorage.setItem('cookieAccepted', 'true');
        setShowBanner(false);
    };
    
    const denyCookies = () => {
        localStorage.setItem('cookieAccepted', 'false');
        setShowBanner(false);
    };

    return (
        showBanner && (
            <Alert variant="primary" className="cookie-banner fixed-bottom">
                <div className="d-flex justify-content-center align-items-center">
                    <p className="mb-0">
                        This website uses cookies to enhance the user experience.{' '}
                        <a href="/about" className="alert-link">Learn more</a>
                    </p>
                    <button onClick={acceptCookies} className="btn btn-dark ms-3">Accept</button>
                    <button onClick={denyCookies} className="btn btn-light ms-2">Decline</button>
                </div>
            </Alert>
        )
    );
};

function HomePage({user}) {
    const navigate = useNavigate();

    const handleGetStarted = () => {
        if (user) {
            navigate('/dashboard');
        } else {
            navigate('/register');
        }
    };

    const heroStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        minHeight: '100vh',
        width: '100%'
    };

    return (
        <div className="homepage">
            <HomeNavbar user={user} boldItem={"home"}/>
            <main>
                {/* Hero Section */}
                <section className="hero" style={heroStyle}>
                    <div className="hero-content">
                        <h1>Optimize Your Productivity Flow</h1>
                        <p>Achieve more with our intelligent productivity platform</p>
                        <div className="hero-buttons">
                            <button onClick={handleGetStarted} className="btn btn-primary btn-lg m-1">
                                {user ? 'Go to Dashboard' : 'Get Started'} <FaArrowRight className="ms-2" />
                            </button>
                            <Link to="/about" className="btn btn-outline-primary btn-lg">Learn More</Link>
                        </div>
                    </div>
                </section>

                {/* Features Section */}
                <section className="features">
                <div className="container mb-5">
                        <div className="about-content">
                            <div className="section-header">
                                <h2>What is OFD?</h2>
                                <p>OFD (Optimal Flow Design) is your dedicated partner in professional and personal growth. We combine cutting-edge technology with proven productivity methods to help you achieve peak performance.</p>
                                <ul className="about-features">
                                    <li>Personalized coaching and guidance</li>
                                    <li>Goal setting and tracking</li>
                                    <li>Performance analytics</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="section-header">
                            <h2>Why Choose OFD?</h2>
                            <p>Optimize your workflow and unlock your full potential with our comprehensive suite of tools</p>
                        </div>
                        

                        <div className="features-grid">
                            <div className="feature-card">
                                <div className="feature-icon">
                                    <BsPersonBadge />
                                </div>
                                <h3>Personal Training</h3>
                                <p>Get personalized guidance and support to achieve your professional goals</p>
                            </div>
                            
                            <div className="feature-card">
                                <div className="feature-icon">
                                    <BsGraphUp />
                                </div>
                                <h3>Performance Tracking</h3>
                                <p>Monitor your progress and optimize your productivity in real-time</p>
                            </div>
                            
                            <div className="feature-card">
                                <div className="feature-icon">
                                    <BsPieChart />
                                </div>
                                <h3>Smart Analytics</h3>
                                <p>Gain insights into your work patterns and improve your efficiency</p>
                            </div>
                        </div>
                        <div className="text-center mt-4">
                            <button onClick={handleGetStarted} className="btn btn-primary btn-lg">
                                Get Started Today <FaArrowRight className="ms-2" />
                            </button>
                        </div>
                    </div>
                </section>

                {/* Screenshots Section */}
                <section className="screenshots">
                    <div className="container">
                        <div className="screenshot-item">
                            <div className="screenshot-content">
                                <h2>Start Your Journey</h2>
                                <p>Begin your productivity transformation with our comprehensive tools and guidance</p>
                            </div>
                            <div className="screenshot-image">
                                <img src={screen1} alt="Daily Routine" />
                            </div>
                        </div>

                        <div className="screenshot-item reverse">
                            <div className="screenshot-content">
                                <h2>Time Management</h2>
                                <p>Efficiently organize your schedule and optimize your daily workflow</p>
                            </div>
                            <div className="screenshot-image">
                                <img src={screen4} alt="Time Management Interface" />
                            </div>
                        </div>

                        <div className="screenshot-item">
                            <div className="screenshot-content">
                                <h2>Track Your Progress</h2>
                                <p>Monitor your daily activities and achievements with detailed insights</p>
                            </div>
                            <div className="screenshot-image">
                                <img src={screen1} alt="Progress Tracking Interface" />
                            </div>
                        </div>

                        <div className="screenshot-item reverse">
                            <div className="screenshot-content">
                                <h2>Visualize Your Growth</h2>
                                <p>See your progress through intuitive charts and analytics</p>
                            </div>
                            <div className="screenshot-image">
                                <img src={screen2} alt="Analytics Interface" />
                            </div>
                        </div>
                    </div>
                </section>

                {/* Mobile App Section */}
                <section className="mobile-app">
                    <div className="container">
                        <div className="mobile-content">
                            <div className="mobile-text">
                                <h2>Take OFD Anywhere</h2>
                                <p>Access your productivity tools and track your progress on the go with our mobile app</p>
                                <button onClick={handleGetStarted} className="btn btn-primary btn-lg">
                                    Start Your Journey <FaArrowRight className="ms-2" />
                                </button>
                            </div>
                            <div className="mobile-image">
                                <img src={phone} alt="OFD Mobile App" />
                            </div>
                        </div>
                    </div>
                </section>

                {/* CTA Section */}
                <section className="cta">
                    <div className="container">
                        <h2>Ready to Transform Your Workflow?</h2>
                        <p>Join thousands of professionals who have already optimized their productivity with OFD</p>
                        <button onClick={handleGetStarted} className="btn btn-light btn-lg">Get Started Today</button>
                    </div>
                </section>
            </main>

            <CookieBanner />
        </div>
    );
}

export default HomePage;
