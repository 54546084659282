import React, { useEffect, useState } from 'react';
import firebase from "../../firebaseConfig";
import {
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    ComposedChart,
    Legend,
    Area,
    Bar,
    RadarChart,
    PolarGrid,
    Radar, PieChart, Pie, Cell, PolarAngleAxis,
} from 'recharts';
import { startOfWeek, endOfWeek, getISOWeek } from "date-fns";
import Cookies from 'js-cookie';
import moment from "moment";

const today = new Date();
const ninetyDaysAgo = new Date(today);
ninetyDaysAgo.setDate(today.getDate() - 90);

const Performance = ({ user }) => {
    const [selectedFromDate, setSelectedFromDate] = useState(ninetyDaysAgo); // Date "from"
    const [selectedToDate, setSelectedToDate] = useState(today); // Date "to"
    const [grandTotal, setGrandTotal] = useState([]);
    const [visibleLines] = useState([
        "Workflow",
        "Creativity",
        "Sport",
        "Communication",
        "Education",
        "Reading",
        "Other",
        "Quality",
        "Total",
    ]);
    const [viewType, setViewType] = useState("daily")
    const fillMissingDates = (aggregatedData, startDate, endDate) => {
        const dateSet = new Set(aggregatedData.map(item => item.date.toDateString()));
        const currentDate = new Date(startDate);

        while (currentDate <= endDate) {
            const currentDateStr = currentDate.toDateString();
            if (!dateSet.has(currentDateStr)) {
                aggregatedData.push({
                    date: new Date(currentDate),
                    Total: 0
                });
                dateSet.add(currentDateStr);
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }

        aggregatedData.sort((a, b) => a.date - b.date);
    };
    const calculateOvernightDuration = (startTime, endTime) => {
        const startMoment = moment(startTime, "HH:mm");
        const endMoment = moment(endTime, "HH:mm");

        let overnightDuration = 0;

        if (endMoment.isBefore(startMoment)) {
            // If the end time is before the start time, it's an overnight task
            const duration = moment.duration(endMoment.add(1, 'day').diff(startMoment));
            overnightDuration = duration.asHours();
        } else {
            // If it's not an overnight task, calculate the duration as usual
            overnightDuration = convertHHMMToHours(endTime) - convertHHMMToHours(startTime);
        }

        return overnightDuration;
    };


    useEffect(() => {
        const storedViewType = Cookies.get("viewType");
        if (storedViewType) {
            setViewType(storedViewType);
        }
        const fetchDateData = async () => {
            try {
                if (user) {
                    const userId = firebase.auth().currentUser.uid;
                    const docRef = firebase.firestore().collection('users').doc(userId);
                    const docSnapshot = await docRef.get();
                    if (docSnapshot.exists) {
                        const data = docSnapshot.data().FlowTrackerData;
                        const filteredData = data.filter((item) =>
                            item.date.seconds * 1000 <= selectedToDate.getTime()
                        );

                        const structuredData = filteredData.map((item) => {
                            const workflowTime = (
                                (item.data.category_1 === "Workflow" ? item.data.nightTask_1 ? calculateOvernightDuration(item.data.time1_0, item.data.time1_1) : convertHHMMToHours (item.data.time1_1) - convertHHMMToHours (item.data.time1_0) : 0) +
                                (item.data.category_2 === "Workflow" ? item.data.nightTask_2 ? calculateOvernightDuration(item.data.time2_0, item.data.time2_1) : convertHHMMToHours (item.data.time2_1) - convertHHMMToHours (item.data.time2_0) : 0) +
                                (item.data.category_3 === "Workflow" ? item.data.nightTask_3 ? calculateOvernightDuration(item.data.time3_0, item.data.time3_1) : convertHHMMToHours (item.data.time3_1) - convertHHMMToHours (item.data.time3_0) : 0) +
                                (item.data.category_4 === "Workflow" ? item.data.nightTask_4 ? calculateOvernightDuration(item.data.time4_0, item.data.time4_1) : convertHHMMToHours (item.data.time4_1) - convertHHMMToHours (item.data.time4_0) : 0) +
                                (item.data.category_5 === "Workflow" ? item.data.nightTask_5 ? calculateOvernightDuration(item.data.time5_0, item.data.time5_1) : convertHHMMToHours (item.data.time5_1) - convertHHMMToHours (item.data.time5_0) : 0) +
                                (item.data.category_6 === "Workflow" ? item.data.nightTask_6 ? calculateOvernightDuration(item.data.time6_0, item.data.time6_1) : convertHHMMToHours (item.data.time6_1) - convertHHMMToHours (item.data.time6_0) : 0) +
                                (item.data.category_7 === "Workflow" ? item.data.nightTask_7 ? calculateOvernightDuration(item.data.time7_0, item.data.time7_1) : convertHHMMToHours (item.data.time7_1) - convertHHMMToHours (item.data.time7_0) : 0)
                            );
                            const creativityTime = (
                                (item.data.category_1 === "Creativity/Arts" ? item.data.nightTask_1 ? calculateOvernightDuration(item.data.time1_0, item.data.time1_1) : convertHHMMToHours (item.data.time1_1) - convertHHMMToHours (item.data.time1_0) : 0) +
                                (item.data.category_2 === "Creativity/Arts" ? item.data.nightTask_2 ? calculateOvernightDuration(item.data.time2_0, item.data.time2_1) : convertHHMMToHours (item.data.time2_1) - convertHHMMToHours (item.data.time2_0) : 0) +
                                (item.data.category_3 === "Creativity/Arts" ? item.data.nightTask_3 ? calculateOvernightDuration(item.data.time3_0, item.data.time3_1) : convertHHMMToHours (item.data.time3_1) - convertHHMMToHours (item.data.time3_0) : 0) +
                                (item.data.category_4 === "Creativity/Arts" ? item.data.nightTask_4 ? calculateOvernightDuration(item.data.time4_0, item.data.time4_1) : convertHHMMToHours (item.data.time4_1) - convertHHMMToHours (item.data.time4_0) : 0) +
                                (item.data.category_5 === "Creativity/Arts" ? item.data.nightTask_5 ? calculateOvernightDuration(item.data.time5_0, item.data.time5_1) : convertHHMMToHours (item.data.time5_1) - convertHHMMToHours (item.data.time5_0) : 0) +
                                (item.data.category_6 === "Creativity/Arts" ? item.data.nightTask_6 ? calculateOvernightDuration(item.data.time6_0, item.data.time6_1) : convertHHMMToHours (item.data.time6_1) - convertHHMMToHours (item.data.time6_0) : 0) +
                                (item.data.category_7 === "Creativity/Arts" ? item.data.nightTask_7 ? calculateOvernightDuration(item.data.time7_0, item.data.time7_1) : convertHHMMToHours (item.data.time7_1) - convertHHMMToHours (item.data.time7_0) : 0)
                            );

                            const sport = (
                                (item.data.category_1 === "Fitness/Sports" ? item.data.nightTask_1 ? calculateOvernightDuration(item.data.time1_0, item.data.time1_1) : convertHHMMToHours (item.data.time1_1) - convertHHMMToHours (item.data.time1_0) : 0) +
                                (item.data.category_2 === "Fitness/Sports" ? item.data.nightTask_2 ? calculateOvernightDuration(item.data.time2_0, item.data.time2_1) : convertHHMMToHours (item.data.time2_1) - convertHHMMToHours (item.data.time2_0) : 0) +
                                (item.data.category_3 === "Fitness/Sports" ? item.data.nightTask_3 ? calculateOvernightDuration(item.data.time3_0, item.data.time3_1) : convertHHMMToHours (item.data.time3_1) - convertHHMMToHours (item.data.time3_0) : 0) +
                                (item.data.category_4 === "Fitness/Sports" ? item.data.nightTask_4 ? calculateOvernightDuration(item.data.time4_0, item.data.time4_1) : convertHHMMToHours (item.data.time4_1) - convertHHMMToHours (item.data.time4_0) : 0) +
                                (item.data.category_5 === "Fitness/Sports" ? item.data.nightTask_5 ? calculateOvernightDuration(item.data.time5_0, item.data.time5_1) : convertHHMMToHours (item.data.time5_1) - convertHHMMToHours (item.data.time5_0) : 0) +
                                (item.data.category_6 === "Fitness/Sports" ? item.data.nightTask_6 ? calculateOvernightDuration(item.data.time6_0, item.data.time6_1) : convertHHMMToHours (item.data.time6_1) - convertHHMMToHours (item.data.time6_0) : 0) +
                                (item.data.category_7 === "Fitness/Sports" ? item.data.nightTask_7 ? calculateOvernightDuration(item.data.time7_0, item.data.time7_1) : convertHHMMToHours (item.data.time7_1) - convertHHMMToHours (item.data.time7_0) : 0)
                            );

                            const other = (
                                (item.data.category_1 === "" ? item.data.nightTask_1 ? calculateOvernightDuration(item.data.time1_0, item.data.time1_1) : convertHHMMToHours (item.data.time1_1) - convertHHMMToHours (item.data.time1_0) : 0) +
                                (item.data.category_2 === "" ? item.data.nightTask_2 ? calculateOvernightDuration(item.data.time2_0, item.data.time2_1) : convertHHMMToHours (item.data.time2_1) - convertHHMMToHours (item.data.time2_0) : 0) +
                                (item.data.category_3 === "" ? item.data.nightTask_3 ? calculateOvernightDuration(item.data.time3_0, item.data.time3_1) : convertHHMMToHours (item.data.time3_1) - convertHHMMToHours (item.data.time3_0) : 0) +
                                (item.data.category_4 === "" ? item.data.nightTask_4 ? calculateOvernightDuration(item.data.time4_0, item.data.time4_1) : convertHHMMToHours (item.data.time4_1) - convertHHMMToHours (item.data.time4_0) : 0) +
                                (item.data.category_5 === "" ? item.data.nightTask_5 ? calculateOvernightDuration(item.data.time5_0, item.data.time5_1) : convertHHMMToHours (item.data.time5_1) - convertHHMMToHours (item.data.time5_0) : 0) +
                                (item.data.category_6 === "" ? item.data.nightTask_6 ? calculateOvernightDuration(item.data.time6_0, item.data.time6_1) : convertHHMMToHours (item.data.time6_1) - convertHHMMToHours (item.data.time6_0) : 0) +
                                (item.data.category_7 === "" ? item.data.nightTask_7 ? calculateOvernightDuration(item.data.time7_0, item.data.time7_1) : convertHHMMToHours (item.data.time7_1) - convertHHMMToHours (item.data.time7_0) : 0)
                            );
                            const communication = (
                                (item.data.category_1 === "Socializing/Communication/Public Speech" ? item.data.nightTask_1 ? calculateOvernightDuration(item.data.time1_0, item.data.time1_1) : convertHHMMToHours (item.data.time1_1) - convertHHMMToHours (item.data.time1_0) : 0) +
                                (item.data.category_2 === "Socializing/Communication/Public Speech" ? item.data.nightTask_2 ? calculateOvernightDuration(item.data.time2_0, item.data.time2_1) : convertHHMMToHours (item.data.time2_1) - convertHHMMToHours (item.data.time2_0) : 0) +
                                (item.data.category_3 === "Socializing/Communication/Public Speech" ? item.data.nightTask_3 ? calculateOvernightDuration(item.data.time3_0, item.data.time3_1) : convertHHMMToHours (item.data.time3_1) - convertHHMMToHours (item.data.time3_0) : 0) +
                                (item.data.category_4 === "Socializing/Communication/Public Speech" ? item.data.nightTask_4 ? calculateOvernightDuration(item.data.time4_0, item.data.time4_1) : convertHHMMToHours (item.data.time4_1) - convertHHMMToHours (item.data.time4_0) : 0) +
                                (item.data.category_5 === "Socializing/Communication/Public Speech" ? item.data.nightTask_5 ? calculateOvernightDuration(item.data.time5_0, item.data.time5_1) : convertHHMMToHours (item.data.time5_1) - convertHHMMToHours (item.data.time5_0) : 0) +
                                (item.data.category_6 === "Socializing/Communication/Public Speech" ? item.data.nightTask_6 ? calculateOvernightDuration(item.data.time6_0, item.data.time6_1) : convertHHMMToHours (item.data.time6_1) - convertHHMMToHours (item.data.time6_0) : 0) +
                                (item.data.category_7 === "Socializing/Communication/Public Speech" ? item.data.nightTask_7 ? calculateOvernightDuration(item.data.time7_0, item.data.time7_1) : convertHHMMToHours (item.data.time7_1) - convertHHMMToHours (item.data.time7_0) : 0)
                            );

                            const education = (
                                (item.data.category_1 === "Education/Learning" ? item.data.nightTask_1 ? calculateOvernightDuration(item.data.time1_0, item.data.time1_1) : convertHHMMToHours (item.data.time1_1) - convertHHMMToHours (item.data.time1_0) : 0) +
                                (item.data.category_2 === "Education/Learning" ? item.data.nightTask_2 ? calculateOvernightDuration(item.data.time2_0, item.data.time2_1) : convertHHMMToHours (item.data.time2_1) - convertHHMMToHours (item.data.time2_0) : 0) +
                                (item.data.category_3 === "Education/Learning" ? item.data.nightTask_3 ? calculateOvernightDuration(item.data.time3_0, item.data.time3_1) : convertHHMMToHours (item.data.time3_1) - convertHHMMToHours (item.data.time3_0) : 0) +
                                (item.data.category_4 === "Education/Learning" ? item.data.nightTask_4 ? calculateOvernightDuration(item.data.time4_0, item.data.time4_1) : convertHHMMToHours (item.data.time4_1) - convertHHMMToHours (item.data.time4_0) : 0) +
                                (item.data.category_5 === "Education/Learning" ? item.data.nightTask_5 ? calculateOvernightDuration(item.data.time5_0, item.data.time5_1) : convertHHMMToHours (item.data.time5_1) - convertHHMMToHours (item.data.time5_0) : 0) +
                                (item.data.category_6 === "Education/Learning" ? item.data.nightTask_6 ? calculateOvernightDuration(item.data.time6_0, item.data.time6_1) : convertHHMMToHours (item.data.time6_1) - convertHHMMToHours (item.data.time6_0) : 0) +
                                (item.data.category_7 === "Education/Learning" ? item.data.nightTask_7 ? calculateOvernightDuration(item.data.time7_0, item.data.time7_1) : convertHHMMToHours (item.data.time7_1) - convertHHMMToHours (item.data.time7_0) : 0)
                            );

                            const reading = (
                                (item.data.category_1 === "Reading" ? item.data.nightTask_1 ? calculateOvernightDuration(item.data.time1_0, item.data.time1_1) : convertHHMMToHours (item.data.time1_1) - convertHHMMToHours (item.data.time1_0) : 0) +
                                (item.data.category_2 === "Reading" ? item.data.nightTask_2 ? calculateOvernightDuration(item.data.time2_0, item.data.time2_1) : convertHHMMToHours (item.data.time2_1) - convertHHMMToHours (item.data.time2_0) : 0) +
                                (item.data.category_3 === "Reading" ? item.data.nightTask_3 ? calculateOvernightDuration(item.data.time3_0, item.data.time3_1) : convertHHMMToHours (item.data.time3_1) - convertHHMMToHours (item.data.time3_0) : 0) +
                                (item.data.category_4 === "Reading" ? item.data.nightTask_4 ? calculateOvernightDuration(item.data.time4_0, item.data.time4_1) : convertHHMMToHours (item.data.time4_1) - convertHHMMToHours (item.data.time4_0) : 0) +
                                (item.data.category_5 === "Reading" ? item.data.nightTask_5 ? calculateOvernightDuration(item.data.time5_0, item.data.time5_1) : convertHHMMToHours (item.data.time5_1) - convertHHMMToHours (item.data.time5_0) : 0) +
                                (item.data.category_6 === "Reading" ? item.data.nightTask_6 ? calculateOvernightDuration(item.data.time6_0, item.data.time6_1) : convertHHMMToHours (item.data.time6_1) - convertHHMMToHours (item.data.time6_0) : 0) +
                                (item.data.category_7 === "Reading" ? item.data.nightTask_7 ? calculateOvernightDuration(item.data.time7_0, item.data.time7_1) : convertHHMMToHours (item.data.time7_1) - convertHHMMToHours (item.data.time7_0) : 0)
                            );
                                                        
                            const convertHHMMToDecimal = (time) => {
                                if (typeof time !== "string" || !time.includes(":")) {
                                    return 0; // Retourne 0 pour les valeurs non valides
                                }
                            
                                const [hours, minutes] = time.split(":").map(Number); // Sépare et convertit en nombres
                                const decimal = hours + minutes / 60; // Ajoute les heures avec les minutes converties
                            
                                // Arrondi aux centièmes
                                return Math.round(decimal * 100) / 100;
                            };
                            console.log();
                            
                            return {
                                date: new Date(item.date.seconds * 1000),
                                Total: convertHHMMToDecimal(item.data.grandTotal),
                                Workflow: workflowTime,
                                Creativity: creativityTime,
                                Sport: sport,
                                Communication: communication,
                                Education: education,
                                Reading: reading,
                                Other: other,
                                Quality: parseFloat(item.data.meanFlowQuality),
                            };
                        });

                        structuredData.sort((a, b) => a.date - b.date);
                        setGrandTotal(structuredData);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchDateData();
    }, [user, selectedFromDate, selectedToDate]);

    const handleFromDateChange = (event) => {
        const newDate = new Date(event.target.value);
        setSelectedFromDate(newDate);
    };

    const handleToDateChange = (event) => {
        const newDate = new Date(event.target.value);
        setSelectedToDate(newDate);
    };
    const aggregateDataByMonth = (data) => {
        const aggregatedData = {};

        data.forEach((item) => {
            const year = item.date.getFullYear();
            const month = item.date.getMonth();
            const yearMonth = `${year}-${month}`;

            if (!aggregatedData[yearMonth]) {
                aggregatedData[yearMonth] = {
                    startOfMonth: new Date(year, month, 1),
                    endOfMonth: new Date(year, month + 1, 0),
                    date: item.date,
                    qualityCount: 0, // Nouvelle propriété pour compter les jours avec une qualité non nulle
                    Quality: 0, // Nouvelle propriété pour stocker la somme de la qualité
                };
            }

            for (const category of Object.keys(lineColors)) {
                if (!aggregatedData[yearMonth][category]) {
                    aggregatedData[yearMonth][category] = 0;
                }

                if (category === "Quality") {
                    // Ne traitez que les jours où la qualité n'est pas nulle
                    if (item[category] !== 0) {
                        aggregatedData[yearMonth].qualityCount++;
                        aggregatedData[yearMonth].Quality += item[category];
                    }
                } else {
                    aggregatedData[yearMonth][category] += item[category];
                }
            }
        });

        // Calcul de la moyenne pour la catégorie "Quality"
        for (const yearMonth of Object.keys(aggregatedData)) {
            if (aggregatedData[yearMonth].qualityCount) {
                aggregatedData[yearMonth].Quality /= aggregatedData[yearMonth].qualityCount;
            }
        }

        return Object.values(aggregatedData);
    };


    const aggregateDataByWeek = (data) => {
        const aggregatedData = {};
        
        data.forEach((item) => {
            const year = item.date.getFullYear();
            const week = getISOWeek(item.date);
            const weekYear = `${week}-${year}`;
            
            if (!aggregatedData[weekYear]) {
                aggregatedData[weekYear] = {
                    startOfWeek: startOfWeek(item.date),
                    endOfWeek: endOfWeek(item.date),
                    date: item.date,
                    qualityCount: 0, // Nouvelle propriété pour compter les jours avec une qualité non nulle
                    Quality: 0, // Nouvelle propriété pour stocker la somme de la qualité
                };
            }

            for (const category of Object.keys(lineColors)) {
                if (!aggregatedData[weekYear][category]) {
                    aggregatedData[weekYear][category] = 0;
                }

                if (category === "Quality") {
                    // Ne traitez que les jours où la qualité n'est pas nulle
                    if (item[category] !== 0) {
                        aggregatedData[weekYear].qualityCount++;
                        aggregatedData[weekYear].Quality += item[category];
                    }
                } else {
                    aggregatedData[weekYear][category] += item[category];
                }
            }
        });

        // Calcul de la moyenne pour la catégorie "Quality"
        for (const weekYear of Object.keys(aggregatedData)) {
            if (aggregatedData[weekYear].qualityCount) {
                aggregatedData[weekYear].Quality /= aggregatedData[weekYear].qualityCount;
            }
        }

        return Object.values(aggregatedData);
    };


    const convertHHMMToHours = (timeHHMM) => {
        if(timeHHMM){
            const [hours, minutes] = timeHHMM.split(":");
            const totalHours = parseFloat(hours) + parseFloat(minutes) / 60;
            return totalHours;
        }
    };

    const formatDate = (date, viewType) => {
        if (!date) {
            return "No Date";
        }

        if (viewType === "daily" || viewType === "weekly") {
            const dayOfWeek = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(date);
            const dayOfMonth = date.getDate();
            const monthNames = [
                "Jan.",
                "Feb.",
                "Mar.",
                "Apr.",
                "May",
                "Jun.",
                "Jul.",
                "Aug.",
                "Sep.",
                "Oct.",
                "Nov.",
                "Dec.",
            ];
            return `${dayOfWeek} ${dayOfMonth} ${monthNames[date.getMonth()]}`;
        } else if (viewType === "monthly") {
            const monthNames = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ];
            const month = monthNames[date.getMonth()];
            const year = date.getFullYear();
            return `${month} ${year}`;
        }
    };


    const lineColors = {
        Workflow: "#6373ff",
        Creativity: "#abff4f",
        Sport: "#c5a400",
        Communication: "#f1232a",
        Education: "#be21e8",
        Reading: "#04ffff",
        Other: "#b0aeae",
        Quality: "#000000",
        Total: "#000000",
    };

    // Fonction pour obtenir le nom du jour à partir de son chiffre
    const getDayName = (dayOfWeek) => {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return days[dayOfWeek];
    };

    const aggregateDataByDayOfWeek = (aggregatedData) => {
        const aggregatedDataByWeek = {
            0: 0, // Dimanche
            1: 0, // Lundi
            2: 0, // Mardi
            3: 0, // Mercredi
            4: 0, // Jeudi
            5: 0, // Vendredi
            6: 0, // Samedi
        };

        grandTotal.forEach((item) => {
            if(item.date > aggregatedData[0].date){
                const dayOfWeek = item.date.getDay(); // Obtenez le jour de la semaine à partir de la date
                if (aggregatedDataByWeek[dayOfWeek] !== undefined) {
                    aggregatedDataByWeek[dayOfWeek] += item.Total;
                }
            }
        });
        return Object.entries(aggregatedDataByWeek).map(([dayOfWeek, Total]) => ({
            dayOfWeek: getDayName(dayOfWeek), // Utilisez une fonction pour obtenir le nom du jour à partir de son chiffre
            Total,
        }));
    };

    const aggregateDataByWeekInMonth = (grandTotal) => {
        const aggregatedDataByWeek = {
            "Week 1": 0,
            "Week 2": 0,
            "Week 3": 0,
            "Week 4": 0,
        };

        grandTotal.forEach((item) => {
            const weekNumber = getWeekNumberInMonth(item.date);

            if (weekNumber > 0 && weekNumber <= 4) {
                if (aggregatedDataByWeek[`Week ${weekNumber}`] !== undefined) {
                    aggregatedDataByWeek[`Week ${weekNumber}`] += item.Total;
                }
            }
        });

        return Object.entries(aggregatedDataByWeek).map(([weekName, Total]) => ({
            weekOfMonth: weekName, // Utilisez "weekOfMonth" comme clé
            Total,
        }));
    };

    const aggregateDataByMonthInYear = (grandTotal) => {
        const aggregatedDataByMonth = {
            "January": 0,
            "February": 0,
            "March": 0,
            "April": 0,
            "May": 0,
            "June": 0,
            "July": 0,
            "August": 0,
            "September": 0,
            "October": 0,
            "November": 0,
            "December": 0
        };

        grandTotal.forEach((item) => {
            const monthName = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(item.date);

            if (aggregatedDataByMonth[monthName] !== undefined) {
                aggregatedDataByMonth[monthName] += item.Total;
            }
        });

        return Object.entries(aggregatedDataByMonth).map(([month, Total]) => ({
            monthOfYear: month,
            Total,
        }));
    };

    const getWeekNumberInMonth = (date) => {
        const dayOfMonth = date.getDate();
        const weekNumber = Math.ceil(dayOfMonth / 7);
    
        // Si la semaine calculée est 5, on la considère comme la semaine 4
        return weekNumber > 4 ? 4 : weekNumber;
    };

    const aggregateDataForPieChart = (data) => {
        const aggregatedData = {
            Workflow: 0,
            Creativity: 0,
            Sport: 0,
            Communication: 0,
            Education: 0,
            Reading: 0,
            Other: 0
        };
        data.forEach((item) => {
            for (const category of Object.keys(aggregatedData)) {
                aggregatedData[category] += item[category];
            }
        });

        return Object.entries(aggregatedData).map(([category, value]) => ({
            name: category,
            value
        }));
    };

    const aggregatedData =
        viewType === "daily"
            ? grandTotal.filter((item) => item.date >= selectedFromDate && item.date < selectedToDate)
            : viewType === "weekly"
                ? aggregateDataByWeek(grandTotal).slice(-26)
                : aggregateDataByMonth(grandTotal).slice(-12);

    const aggregatedDataForRadar = viewType === "daily"
        ? aggregateDataByDayOfWeek(aggregatedData)
        : viewType === "weekly"
            ? aggregateDataByWeekInMonth(aggregatedData)
            : aggregateDataByMonthInYear(aggregatedData);

    const aggregatedDataForPie = aggregateDataForPieChart(aggregatedData);

    const convertDecimalToHHMM = (decimal) => {
        const hours = Math.floor(decimal); // Partie entière : heures
        const minutes = Math.round((decimal - hours) * 60); // Partie décimale : minutes
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    };

    if(viewType === "daily"){
        fillMissingDates(aggregatedData, selectedFromDate, selectedToDate)
    }

    const getBarColor = (value) => {
        // Utilisez ici votre propre logique pour attribuer des couleurs en fonction de la valeur
        if (value >= 0 && value <1){ return "#862800" } else
        if (value >= 1 && value <2){ return "#d34100" } else
        if (value >= 2 && value <3){ return "#8822f5" } else
        if (value >= 3 && value <4){ return "#4066ff" } else
        if (value >= 4){ return "#19c3ff" }
    };
    // Sélectionner les couleurs pour les catégories
    const categoryColors = {
            Workflow: "#6373ff",
            Creativity: "#6dce00",
            Sport: "#e7c008",
            Communication: "#f1232a",
            Education: "#be21e8",
            Reading: "#4fe6f5",
            Other: "#b0aeae",
        };
    return (
        <div>
            <div className={"mt-3 d-flex flex-column align-items-center"}>
                <div className="d-flex justify-content-between w-100">
                    <div className="d-flex flex-row">
                        <div className="m-2">
                            <div style={{ position: "absolute", top: "5.1em", fontSize:'.8em'}}>From</div>
                            <input
                                type="date"
                                value={selectedFromDate.toISOString().split('T')[0]}
                                onChange={handleFromDateChange}
                                disabled={viewType !== "daily"}
                            />
                        </div>
                        <div className="m-2">
                            <div style={{ position: "absolute", top: "5.1em", fontSize:'.8em'}}>To</div>
                            <input
                                type="date"
                                value={selectedToDate.toISOString().split('T')[0]}
                                onChange={handleToDateChange}
                                disabled={viewType !== "daily"}
                            />
                        </div>
                    </div>
                    <div className="btn-group" role="group" aria-label="Basic example">
                        <button type="button" className={`btn btn-primary ${viewType === "daily" ? "active" : ""}`} onClick={() => setViewType("daily")}>
                            Daily
                        </button>
                        <button type="button" className={`btn btn-primary ${viewType === "weekly" ? "active" : ""}`} onClick={() => setViewType("weekly")}>
                            Weekly
                        </button>
                        <button type="button" className={`btn btn-primary ${viewType === "monthly" ? "active" : ""}`} onClick={() => setViewType("monthly")}>
                            Monthly
                        </button>
                    </div>
                    <div></div>
                </div>
                <div className={"d-flex w-100 "}>
                    <div className="w-100 mt-3 d-flex">
                        <div className="w-75 mainCharts">
                        <ResponsiveContainer className={"totalChart"} width="100%" height={410}>
                            <ComposedChart data={aggregatedData} margin={{ bottom: 50 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <Legend />
                                <XAxis 
                                    dataKey="date" 
                                    tickFormatter={(value) => formatDate(value, viewType)} 
                                    angle={-70} 
                                    textAnchor="end" 
                                    tick={{ fontSize: 11 }} 
                                />
                                <YAxis 
                                    tickFormatter={(value) => convertDecimalToHHMM(value)} // Conversion HH:MM
                                />
                                <Tooltip 
                                    formatter={(value, name) => `${convertDecimalToHHMM(value)}`} // Conversion HH:MM
                                    labelFormatter={(label) => `Date: ${formatDate(label, viewType)}`} 
                                />
                                {visibleLines.map((line) => (
                                    line !== visibleLines[7] ? (
                                        line === visibleLines[8] ? (
                                            <Area
                                                key={line}
                                                type="monotone"
                                                dataKey={line}
                                                name={line}
                                                stroke={"#004bbe"}
                                                fill={"rgba(0,74,148,0.11)"}
                                            />
                                        ) : null
                                    ) : (
                                        <Bar
                                            key={visibleLines[7]}
                                            dataKey={visibleLines[7]}
                                            name={visibleLines[7]}
                                            barSize={10}
                                            stackId="stack"
                                            fill="transparent"
                                        />
                                    )
                                ))}
                            </ComposedChart>
                        </ResponsiveContainer>
                            <ResponsiveContainer width="100%" height={320} className={"mt-4"}>
                                <ComposedChart data={aggregatedData} margin={{ bottom: 50 }}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="date" tickFormatter={(value) => formatDate(value, viewType)} angle={-70} textAnchor="end" tick={{ fontSize: 11 }} />
                                    <YAxis tickFormatter={(value) => value} domain={[1, 5]} ticks={[1, 2, 3, 4, 5]} />
                                    <Tooltip labelFormatter={(label) => `Date: ${formatDate(label, viewType)}`} />
                                    <Legend />
                                    <Bar
                                        key={visibleLines[7]}
                                        dataKey={visibleLines[7]}
                                        name={visibleLines[7]}
                                        barSize={10}
                                        stackId="stack"
                                    >
                                        {
                                            aggregatedData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={getBarColor(entry[visibleLines[7]])} />
                                            ))
                                        }
                                    </Bar>
                                </ComposedChart>
                            </ResponsiveContainer>                  {/*2*/}
                        </div>
                        <div className="w-25">
                            <ResponsiveContainer width="100%" height={300} className={"mt-3"}>
                            <RadarChart outerRadius={90} width={730} height={250} data={aggregatedDataForRadar}>
                                <PolarGrid radialLines />
                                    <Tooltip 
                                        formatter={(value, name) => `${convertDecimalToHHMM(value)}h`} // Conversion HH:MM
                                    />
                                    <Legend />
                                    <Radar 
                                        name="Total" 
                                        dataKey="Total" 
                                        stroke="#004bbe" 
                                        fill="#004bbe" 
                                        fillOpacity={0.6} 
                                        label={false} 
                                    />
                                    <PolarAngleAxis
                                        dataKey={viewType === "daily" ? "dayOfWeek" : viewType === "weekly" ? "weekOfMonth" : "monthOfYear"}
                                        tick={{ fontSize: 12, fill: "#000" }}
                                    />
                                </RadarChart>
                            </ResponsiveContainer>

                            <ResponsiveContainer width="100%" height={300} className={"mt-5"}>
                                <PieChart>
                                    <Pie
                                        dataKey="value"
                                        data={aggregatedDataForPie}
                                        nameKey="name"
                                        outerRadius={80}
                                        fill="#8884d8"
                                    >
                                        {Object.keys(categoryColors).map((category, index) => (
                                            <Cell key={category} fill={categoryColors[category]} />
                                        ))}
                                    </Pie>
                                    <Legend />
                                    <Tooltip
                                        formatter={(value, name) => {
                                            // Calcul du pourcentage
                                            const totalValue = aggregatedDataForPie.reduce((total, entry) => total + entry.value, 0);
                                            const percentage = totalValue !== 0 ? ((value / totalValue) * 100).toFixed(2) : 0;

                                            // Conversion de la valeur en hh:mm
                                            const hours = Math.floor(value);
                                            const minutes = Math.round((value - hours) * 60);
                                            const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')}`;

                                            return `${formattedTime}h (${percentage}%)`;
                                        }}
                                    />
                                </PieChart>
                            </ResponsiveContainer>                  {/*4*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Performance;