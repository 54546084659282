import firebase from '../firebaseConfig';
import { getFirestore, doc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import "../styles/positiveAffirmations.css";
import { useEffect, useState } from "react";
import CustomModal from "./Dashboard_Tabs/CustomModal";
import DashBoardNavBar from "./DashboardNavbar";
import { useNavigate } from "react-router-dom";
import { TiPencil, TiPlus, TiDelete } from "react-icons/ti";
import NoSubscription from "./NoSubscription";
import { v4 as uuidv4 } from 'uuid'; // Pour générer des identifiants uniques

function PositiveAffirmations({ user }) {
    const [planValidity, setPlanValidity] = useState(0); // État de validité de l'abonnement
    const [userName, setUserName] = useState(""); // Nom d'utilisateur
    const [positiveAffirmation, setPositiveAffirmation] = useState(""); // Affirmation positive
    const [journalEntries, setJournalEntries] = useState([]); // Entrées du journal
    const [modalIsOpen, setModalIsOpen] = useState(false); // État de la modale
    const [modalTitle, setModalTitle] = useState(""); // Titre de la modale
    const [fieldName, setFieldName] = useState(""); // Nom du champ à modifier
    const [currentContent, setCurrentContent] = useState(""); // Contenu actuel à modifier
    const [isLoading, setIsLoading] = useState(true); // État de chargement
    const navigate = useNavigate();

    // Vérifie si l'utilisateur a un abonnement valide
    useEffect(() => {
        const checkPlanValidity = async () => {
            if (!user) {
                navigate('/login');
                return;
            }

            try {
                const userId = user.uid;
                const docRef = firebase.firestore().collection('users').doc(userId);
                const docSnapshot = await docRef.get();

                if (docSnapshot.exists) {
                    const data = docSnapshot.data();
                    const endSubscriptionDate = data.endSubscriptionDate.toDate();
                    setUserName(data.username);

                    if (endSubscriptionDate > new Date()) {
                        setPlanValidity(2);
                        if (!data.tutorialCompleted) {
                            navigate('/welcome');
                        }
                        setPositiveAffirmation(data.positiveAffirmation || "");

                        const journal = data.journal || []; // Récupère le tableau des journaux
                        if (!data.journal) {
                            // Initialisez le champ 'journal' avec un tableau vide
                            await updateDoc(docRef, { journal: [] });
                        }
                        setJournalEntries(journal);
                        console.log("test");
                        console.log(journal);
                        
                    } else {
                        setPlanValidity(1);
                    }
                }
            } catch (error) {
                console.error("Erreur lors de la vérification de l'abonnement:", error);
            }
            setIsLoading(false);
        };

        checkPlanValidity();
    }, [user, navigate]);

    const handleDeleteEntry = (entryId) => {
        const updatedJournalEntries = journalEntries.filter(entry => entry.id !== entryId);
        setJournalEntries(updatedJournalEntries);
        console.log("del" ,entryId);
        
        // Mise à jour de la base de données Firestore
        const userId = user.uid;
        const docRef = firebase.firestore().collection('users').doc(userId);
        docRef.update({ journal: updatedJournalEntries });
    };

    // Met à jour la valeur d'un champ utilisateur dans Firestore
    const updateInputValue = async (newValue) => {
        if (!user) return;

        try {
            const userId = user.uid;
            const docRef = firebase.firestore().collection('users').doc(userId);
            await docRef.update({ [fieldName]: newValue });
            if (fieldName === "positiveAffirmation") {
                setPositiveAffirmation(newValue); // Met à jour localement
            }
            closeModal();
        } catch (error) {
            console.error("Erreur lors de la mise à jour du champ:", error);
        }
    };

    // Ajoute une nouvelle entrée au journal
    const addJournalEntry = async (message) => {
        if (!user) return;

        try {
            const userId = user.uid;
            const newEntry = {
                id: uuidv4(), // Génère un identifiant unique
                message,
                date: new Date().toISOString(),
            };

            // Met à jour le tableau "journal" dans Firestore
            const docRef = firebase.firestore().collection('users').doc(userId);
            await docRef.update({
                journal: firebase.firestore.FieldValue.arrayUnion(newEntry),
            });

            // Met à jour l'état local
            setJournalEntries([newEntry, ...journalEntries]);
            closeModal();
        } catch (error) {
            console.error("Erreur lors de l'ajout de l'entrée au journal:", error);
        }
    };

    // Ouvre la modale avec les paramètres fournis
    const openModal = (title, field = "", content = "") => {
        setModalTitle(title);
        setFieldName(field);
        setCurrentContent(content);
        setModalIsOpen(true);
    };

    // Ferme la modale
    const closeModal = () => {
        setModalIsOpen(false);
    };

    // Fonction pour regrouper les journaux par date
    const groupJournalEntriesByDate = (entries) => {
        return entries.reduce((acc, entry) => {
            const dateKey = new Date(entry.date).toDateString();
            if (!acc[dateKey]) {
                acc[dateKey] = [];
            }
            acc[dateKey].push({ id: entry.id, message: entry.message });
            return acc;
        }, {});
    };

    const groupedJournalEntries = groupJournalEntriesByDate(journalEntries);

    if (isLoading) {
        return (
            <div className="loading-container">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <>
            {/* Contenu principal si l'abonnement est valide */}
            {planValidity === 2 && (
                <>
                    <DashBoardNavBar userName={userName} boldItem="positiveAffirmations" />
                    <section className="position-relative pt-3">
                        <div className="container">
                            <div className="affirmations-section">
                                <h4 className="section-title">POSITIVE AFFIRMATION</h4>
                                <div className="mb-4 fondGris">
                                    <p
                                        style={{ cursor: "pointer" }}
                                        onClick={() => openModal("Edit Positive Affirmation", "positiveAffirmation", positiveAffirmation)}
                                    >
                                        <span style={{ textDecoration: "underline" }}>Positive Affirmations</span>
                                        <TiPencil className="edit-icon action-icon" style={{ marginLeft: "0.1em" }} />
                                    </p>
                                    <div
                                        onDoubleClick={() => openModal("Edit Positive Affirmation", "positiveAffirmation", positiveAffirmation)}
                                        className="contentText"
                                    >
                                        {positiveAffirmation || " "}
                                    </div>
                                </div>
                            </div>
                            
                            <div className="journaling-section">
                                <h4 className="section-title">
                                    JOURNALING 
                                    <div 
                                        className="add-button"
                                        onClick={() => openModal("Add Journal Entry", "", "")} 
                                    >
                                        <TiPlus />
                                    </div>
                                </h4>
                                <div className="journal-entries">
                                    {Object.keys(groupedJournalEntries).map(date => (
                                        <div key={date} className="journal-date-group">
                                            <h5 className="journal-date">{date}</h5>
                                            <ul className="journal-entry-list">
                                            {groupedJournalEntries[date].slice()
                                            .reverse()
                                            .map(entry => (
                                                <li key={entry.id} className="journal-entry">
                                                    <div className="entry-content">
                                                        <TiDelete
                                                            className="delete-icon action-icon"
                                                            onClick={() => handleDeleteEntry(entry.id)}
                                                        />
                                                        <p>{entry.message}</p>
                                                    </div>
                                                </li>
                                            ))}
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )}


            {planValidity === 1 && <NoSubscription />}

            {modalIsOpen && (
                <CustomModal
                    title={modalTitle}
                    closeModal={closeModal}
                    content={currentContent}
                    isOpen={modalIsOpen}
                    saveField={fieldName ? updateInputValue : addJournalEntry}
                    className="affirmations-modal"
                />
            )}
        </>
    );
}

export default PositiveAffirmations;
