import React from 'react';
import HomeNavbar from "./HomeNavbar";
import '../styles/About.css';
import { FaCheckCircle, FaUsers, FaLightbulb, FaRocket } from 'react-icons/fa';

const AboutUs = ({user}) => {
    return (
        <>
            <HomeNavbar user={user} boldItem={"about"}/>
            <section className="about-section">
                <div className="about-container">
                    <div className="about-header">
                        <h1>About OFD</h1>
                        <p>Empowering individuals to achieve their full potential through innovative productivity solutions</p>
                    </div>

                    <div className="about-grid">
                        <div className="about-card">
                            <h2>Our Vision</h2>
                            <p>At OFD, we believe that everyone has the potential to achieve their goals and dreams. Our vision is to create a world where individuals can effortlessly manage their time, maintain focus, and accomplish their objectives without being overwhelmed by the demands of modern life.</p>
                        </div>

                        <div className="about-card">
                            <h2>Our Mission</h2>
                            <p>We're dedicated to providing cutting-edge productivity tools and methodologies that help you work smarter, not harder. Our platform combines proven productivity techniques with modern technology to create a seamless, effective workflow management system.</p>
                        </div>
                    </div>

                    <div className="about-card">
                        <h2>Why Choose OFD?</h2>
                        <ul className="about-list">
                            <li>
                                <FaCheckCircle />
                                <span>Personalized productivity solutions tailored to your unique needs</span>
                            </li>
                            <li>
                                <FaUsers />
                                <span>Join a community of motivated individuals focused on personal growth</span>
                            </li>
                            <li>
                                <FaLightbulb />
                                <span>Access to innovative tools and proven productivity methodologies</span>
                            </li>
                            <li>
                                <FaRocket />
                                <span>Continuous platform improvements based on user feedback and research</span>
                            </li>
                        </ul>
                    </div>

                    <div className="about-team">
                        <h2>Our Approach</h2>
                        <div className="team-grid">
                            <div className="team-member">
                                <FaLightbulb size={40} color="#0b5ed7" />
                                <h3>Smart Planning</h3>
                                <p>Intelligent task management and scheduling to optimize your workflow</p>
                            </div>
                            <div className="team-member">
                                <FaUsers size={40} color="#0b5ed7" />
                                <h3>Collaborative Growth</h3>
                                <p>Share insights and learn from a community of productive individuals</p>
                            </div>
                            <div className="team-member">
                                <FaRocket size={40} color="#0b5ed7" />
                                <h3>Continuous Innovation</h3>
                                <p>Regular updates and new features to enhance your productivity journey</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Section about Cookies and Data Privacy */}
            <section className="bg-light py-3">
                <div className="container">
                    <div className="row">
                        <div className="">
                            <h5>Cookies and Data Privacy</h5>
                            <p>
                                At <strong>OFD</strong>, we take your privacy seriously. This website uses cookies to enhance your browsing experience and provide personalized content.
                            </p>
                            <p>
                                Rest assured, your data is treated with the utmost confidentiality. We do not share your personal information with third parties without your explicit consent. For more details on how we handle your data, please review our Privacy Policy and Cookie Policy.
                            </p>
                            <p>
                                If you have any questions or concerns about our privacy practices, please contact us at email waoist@yahoo.com.
                            </p>
                            <h5>Refunds</h5>
                            <p>Please contact jason@gmail.com to ask for a refund. Refund requests are evaluated individually.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AboutUs;
