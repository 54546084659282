import firebase from "../firebaseConfig";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const DashBoardNavBar = ({ userName, boldItem }) => {
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await firebase.auth().signOut();
            navigate('/');
        } catch (error) {
            // Handle logout errors
        }
    };

    return (
        <nav className="navbar navbar-light navbar-expand-lg bg-light navigation-clean p-2">
            <div className="container mainContainer">
                <a className="navbar-brand" href="/">
                    <img src="img.png" alt="logoOFD" width={"50"}/>
                </a>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navcol-1"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navcol-1">
                    <ul className="navbar-nav ml-auto">
                        <li className={`nav-item ${boldItem === 'dashboard' ? 'fw-bold' : ''}`}>
                            <a className="nav-link" href="/dashboard">
                                FLOW STATE HABITS
                            </a>
                        </li>
                        <li className={`nav-item ${boldItem === 'flowTracker' ? 'fw-bold' : ''}`}>
                            <a className="nav-link" href="/flowtracker">
                                FLOW TRACKER
                            </a>
                        </li>
                        <li className={`nav-item ${boldItem === 'cycles' ? 'fw-bold' : ''}`}>
                            <a className="nav-link" href="/cycles">
                                CYCLES
                            </a>
                        </li>
                        <li className={`nav-item ${boldItem === 'positiveAffirmations' ? 'fw-bold' : ''}`}>
                            <a className="nav-link" href="PositiveAffirmations">
                                POSITIVE AFFIRMATIONS & JOURNALING
                            </a>
                        </li>
                        <li className={`nav-item ${boldItem === 'tutorials' ? 'fw-bold' : ''}`}>
                            <a className="nav-link" href="Tutorials">
                                TUTORIALS
                            </a>
                        </li>
                        <li className={`nav-item ${boldItem === 'personnalTraining' ? 'fw-bold' : ''}`}>
                            <a className="nav-link" href="personnalTraining">
                                PERSONAL TRAINING
                            </a>
                        </li>
                        <li className={`nav-item ${boldItem === 'feedback' ? 'fw-bold' : ''}`}>
                            <a className="nav-link" href="feedback">
                                REPORT A BUG / GIVE FEEDBACK
                            </a>
                        </li>
                    </ul>
                    <Dropdown align="end" className="ms-auto">
                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                            {userName && userName}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => navigate('/account')}>My Account</Dropdown.Item>
                            <Dropdown.Item onClick={handleLogout}>Disconnect</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </nav>
    );
};

export default DashBoardNavBar;
