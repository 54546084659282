import React, { useState, useRef, useEffect } from 'react';
import OpenAI from 'openai';
import '../styles/chatbot.css';
import ReactMarkdown from 'react-markdown';

const ChatBot = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const messagesEndRef = useRef(null);

    const systemMessage = {
        role: 'system',
        content: `You are a professional Optimal Flow Development (OFD) Coach. OFD is a comprehensive methodology focused on helping individuals achieve and maintain optimal flow states in their work and life.

Key aspects of OFD that you should guide users on:
1. Flow State Mastery: Helping users understand and achieve the psychological state of flow where they are fully immersed and focused
2. Flow Triggers: Identifying and optimizing personal and environmental triggers that facilitate flow states
3. Single-Tasking: Teaching the importance of focused, uninterrupted work periods
4. Release & Recovery: Guiding users on effective stress management and recovery techniques
5. Flow Routines: Helping establish daily routines that maximize flow state opportunities
6. Distractions Management: Strategies for minimizing interruptions and maintaining focus
7. Time Management: Optimal scheduling techniques for peak performance periods
8. Logistics Optimization: Organizing workspace and tools for minimal friction
9. Sticking Plan: Developing sustainable habits and overcoming obstacles
10. Causes & Symptoms: Understanding personal barriers to flow and addressing them

Your role is to provide personalized coaching, answer questions, and offer practical strategies based on OFD principles. Be supportive, professional, and focused on helping users achieve their optimal flow state.`
    };

    useEffect(() => {
        // Show welcome message when chat is first opened
        if (isOpen && messages.length === 0) {
            setMessages([{
                role: 'assistant',
                content: "Hello! I'm your OFD (Optimal Flow Development) Coach. I'm here to help you achieve and maintain optimal flow states in your work and life.\n\n**Here's how I can assist you:**\n\n1. Flow State Mastery\n2. Flow Triggers\n3. Single-Tasking\n4. Recovery Techniques\n5. Flow Routines\n\n*Feel free to ask any questions!*"
            }]);
        }
    }, [isOpen]);

    const openai = new OpenAI({
        apiKey: process.env.REACT_APP_OPENAI_API_KEY,
        dangerouslyAllowBrowser: true
    });

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!inputMessage.trim()) return;

        const userMessage = { role: 'user', content: inputMessage };
        setMessages(prev => [...prev, userMessage]);
        setInputMessage('');
        setIsLoading(true);

        try {
            const completion = await openai.chat.completions.create({
                messages: [systemMessage, ...messages, userMessage].map(msg => ({
                    role: msg.role,
                    content: msg.content
                })),
                model: "gpt-3.5-turbo",
            });

            const assistantMessage = {
                role: 'assistant',
                content: completion.choices[0].message.content
            };
            setMessages(prev => [...prev, assistantMessage]);
        } catch (error) {
            console.error('Error:', error);
            setMessages(prev => [...prev, {
                role: 'assistant',
                content: 'Sorry, I encountered an error. Please try again.'
            }]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleToggle = () => {
        if (isOpen) {
            setIsClosing(true);
            setTimeout(() => {
                setIsOpen(false);
                setIsClosing(false);
            }, 300); // Match animation duration
        } else {
            setIsOpen(true);
        }
    };

    return (
        <div className="chatbot-container">
            <button 
                className={`chatbot-toggle ${isOpen ? 'open' : ''}`}
                onClick={handleToggle}
            >
                {isOpen ? '×' : '💬'}
            </button>
            
            {(isOpen || isClosing) && (
                <div className={`chatbot-window ${isOpen ? 'open' : 'close'}`}>
                    <div className="chatbot-header">
                        <h3>OFD Assistant</h3>
                    </div>
                    <div className="chatbot-messages">
                        {messages.map((message, index) => (
                            <div
                                key={index}
                                className={`message ${message.role === 'user' ? 'user' : 'assistant'}`}
                            >
                                <ReactMarkdown>{message.content}</ReactMarkdown>
                            </div>
                        ))}
                        {isLoading && (
                            <div className="message assistant">
                                <div className="typing-indicator">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        )}
                        <div ref={messagesEndRef} />
                    </div>
                    <form onSubmit={handleSubmit} className="chatbot-input">
                        <input
                            type="text"
                            value={inputMessage}
                            onChange={(e) => setInputMessage(e.target.value)}
                            placeholder="Type your message..."
                            disabled={isLoading}
                        />
                        <button type="submit" disabled={isLoading}>
                            Send
                        </button>
                    </form>
                </div>
            )}
        </div>
    );
};

export default ChatBot;
