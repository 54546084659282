import React, { useEffect, useState } from 'react';
import firebase from "../../firebaseConfig";
import "../../styles/generalTabs.css"
import "../../styles/tableTasks.css"
import "../../styles/kanbanBoard.css"
import KanbanBoard from "../KanbanBoard";
import {TiDelete, TiPlus} from "react-icons/ti";
import SingleTaskingModal from "./SingleTaskingModal";
import { UncontrolledBoard } from '@caldwell619/react-kanban';

const columnsName = {
    "priorities_action_list": "PRIORITIES & NON-NEGOTIABLE",
    "secondary_action_list": "SECONDARY",
    "easiest_action_list": "EASIEST & FAVORITE",
    "clustering_action_list": "CLUSTERING",
    "delegating_action_list": "DELEGATING",
}
const SingleTasking = ({ user }) => {
    const [singleTaskingData, setSingleTaskingData] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (user) {
                    const userId = firebase.auth().currentUser.uid;
                    const docRef = firebase.firestore().collection('users').doc(userId);
                    const doc = await docRef.get();
                    const userData = doc.data();
                    const singleTasking2Data = userData.SingleTasking_2 || {};
                    const order = [
                        'priorities_action_list',
                        'secondary_action_list',
                        'easiest_action_list',
                        'clustering_action_list',
                        'delegating_action_list',
                    ];
                    const sortedSingleTaskingData = {};
                    order.forEach((key) => {
                        if (singleTasking2Data.hasOwnProperty(key)) {
                            sortedSingleTaskingData[key] = singleTasking2Data[key];
                        }
                    });
                    setSingleTaskingData(sortedSingleTaskingData);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            }
        };
        fetchData();
    }, [user]);

    const openModal = () => {
        setModalIsOpen(true);
    };
    const closeModal = () => {
        setModalIsOpen(false);
    };
    
    const handleCreateTask = (newTask) => {
        // Create a copy of the current data
        const updatedSingleTaskingData = { ...singleTaskingData };
    
        // Find the corresponding category
        const category = updatedSingleTaskingData[newTask.category];
    
        // Ensure the category exists
        if (!category) {
            console.error(`Category "${newTask.category}" does not exist.`);
            return;
        }
    
        // Find the `TODO` column
        let todoColumn = category.columns.find((col) => col.title === 'TODO');
    
        if (todoColumn) {
            console.log("Renaming TODO column to TO-DO.");
            todoColumn.title = 'TO-DO';
        } else {
            // If no TODO column exists, try to find the already renamed TO-DO column
            todoColumn = category.columns.find((col) => col.title === 'TO-DO');
        }
    
        // If there's no column to work with, stop here
        if (!todoColumn) {
            console.error("Neither TODO nor TO-DO column found. Cannot add task.");
            return;
        }
    
        // Debug: Ensure the column has a cards array
        if (!Array.isArray(todoColumn.cards)) {
            console.log("Initializing cards array in TO-DO column.");
            todoColumn.cards = [];
        }
    
        // Generate a new ID for the task
        const newTaskId = Math.random() * 100000;
    
        // Create the new task with the generated ID
        const newTaskWithId = {
            id: newTaskId,
            title: newTask.title,
            description: newTask.description,
        };
    
        // Add the new task to the TO-DO column
        todoColumn.cards.push(newTaskWithId);
    
        // Debug: Confirm task addition
        console.log("New task added to TO-DO column:", newTaskWithId);
    
        // Save the updated data to Firebase
        saveData(newTask.category, updatedSingleTaskingData[newTask.category]);
    
        // Debug: Confirm successful save
        console.log("Updated data saved successfully for category:", newTask.category);
    };

    const saveData = async (datalistName, datalistData) => {
        try {
            if (user) {
                const userId = firebase.auth().currentUser.uid;
                const docRef = firebase.firestore().collection('users').doc(userId);

                // Obtenez les données existantes de SingleTasking_2
                const doc = await docRef.get();
                const userData = doc.data();
                const singleTaskingData = userData.SingleTasking_2 || {}; // S'assurer qu'il y a un objet SingleTasking_2

                // Mettez à jour la sous-liste spécifique (datalistName)
                singleTaskingData[datalistName] = datalistData;

                const order = [
                    'priorities_action_list',
                    'secondary_action_list',
                    'easiest_action_list',
                    'clustering_action_list',
                    'delegating_action_list',
                ];
                const sortedSingleTaskingData = {};
                order.forEach((key) => {
                    if (singleTaskingData.hasOwnProperty(key)) {
                        sortedSingleTaskingData[key] = singleTaskingData[key];
                    }
                });

                // Mettez à jour les données dans Firebase
                await docRef.update({
                    SingleTasking_2: sortedSingleTaskingData,
                });
                setSingleTaskingData(sortedSingleTaskingData);
            }
        } catch (error) {
            console.error('Erreur lors de la sauvegarde des données :', error);
        }
    };

    const handleDeleteTask = (task, column, categoryName) => {
        // Créez une copie des données actuelles
        const updatedSingleTaskingData = { ...singleTaskingData };
        const category = updatedSingleTaskingData[categoryName];

        // Trouvez la colonne correspondante
        const columnIndex = category.columns.findIndex((col) => col.id === column.id);

        if (columnIndex !== -1) {
            // Trouvez la tâche correspondante
            const taskIndex = category.columns[columnIndex].cards.findIndex((card) => card.id === task.id);

            if (taskIndex !== -1) {
                // Supprimez la tâche
                category.columns[columnIndex].cards.splice(taskIndex, 1);

                // Mettez à jour les données
                setSingleTaskingData(singleTaskingData);

                // Appelez la fonction de sauvegarde pour mettre à jour les données dans Firebase
                saveData(categoryName, updatedSingleTaskingData[categoryName]);
            }
        }
    };

    return (
        <div className="tab-pane containerDashboardContent container-fluid" role="tabpanel" id="tab-2">
            {modalIsOpen && (
                <SingleTaskingModal closeModal={closeModal} onCreate={handleCreateTask}/>
            )}
            <h5 className={"mt-3"}>
                <span className="introText">
                    "A dream written down with a date becomes a goal. A goal broken down into steps becomes a plan. A plan backed by actions makes your dream come true." - Greg S. Reid
                </span>
            </h5>

            <div className="container mt-5">
                <div className="row">
                    <div className="col col-xs-12 col-sm-12 col-md-3 col-lg-3">
                        <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <a className="nav-link active" id="single-tasking-tab" data-toggle="pill" href="#single-tasking" role="tab" aria-controls="single-tasking" aria-selected="true">SINGLE-TASKING</a>
                            <a className="nav-link" id="priorities-tab" data-toggle="pill" href="#priorities" role="tab" aria-controls="priorities" aria-selected="false">PRIORITIES & NON-NEGOTIABLE ACTION LIST</a>
                            <a className="nav-link" id="secondary-action-list-tab" data-toggle="pill" href="#secondary-action-list" role="tab" aria-controls="secondary-action-list" aria-selected="false">SECONDARY ACTION LIST</a>
                            <a className="nav-link" id="favorite-action-list-tab" data-toggle="pill" href="#favorite-action-list" role="tab" aria-controls="favorite-action-list" aria-selected="false">EASIEST & FAVORITE ACTION LIST</a>
                            <a className="nav-link" id="clustering-action-list-tab" data-toggle="pill" href="#clustering-action-list" role="tab" aria-controls="clustering-action-list" aria-selected="false">CLUSTERING ACTION LIST</a>
                            <a className="nav-link" id="delegating-action-list-tab" data-toggle="pill" href="#delegating-action-list" role="tab" aria-controls="delegating-action-list" aria-selected="false">DELEGATING ACTION LIST</a>
                        </div>
                    </div>
                    {
                        (singleTaskingData !== null && singleTaskingData !== undefined && singleTaskingData !== {}) ?
                        <div className="col col-xs-12 col-sm-12 col-md-9 col-lg-9 tasktable">
                            <div className="tab-content" id="v-pills-tabContent">
                                <div className="tab-pane fade show active" id="single-tasking" role="tabpanel" aria-labelledby="single-tasking-tab">
                                    <div className="tab-pane fade show active " id="single-tasking" role="tabpanel" aria-labelledby="single-tasking-tab">
                                        <div className="tab-pane fade show active" id="single-tasking" role="tabpanel" aria-labelledby="single-tasking-tab">
                                            <div>
                                                <div className="d-flex m-2 d-flex justify-content-center" onClick={() => openModal(true)} style={{ cursor:"pointer" }}>
                                                    <h5>TASKS</h5>
                                                    <TiPlus style={{ fontSize: "1.3em", cursor:"pointer", marginLeft:".3em", marginTop:".2em"}} className={"text-primary"} />
                                                </div>
                                                <table className="table minHeightTask tableTasks">
                                                    <thead>
                                                    <tr>
                                                        {Object.keys(singleTaskingData).map((categoryName, index) => (
                                                            <th key={index}>{columnsName[categoryName]}</th>
                                                        ))}
                                                    </tr>
                                                    </thead>
                                                    <tbody >
                                                    <tr>
                                                        {Object.keys(singleTaskingData).map((categoryName, index) => (
                                                            <td key={index}>
                                                                {singleTaskingData[categoryName].columns.map((column) => (
                                                                    <div key={column.id}>
                                                                        <div>
                                                                            {column.cards.map((card, cardIndex) => (
                                                                                <div key={cardIndex} className="d-flex justify-content-between" style={{wordBreak: 'break-word', marginTop:'.2em', marginBottom:"1.2em"}}>
                                                                                    {card.title}
                                                                                    <div className="d-flex">
                                                                                        {column.title === 'TO-DO' && (
                                                                                            <span className="todo-icon">🚀</span>
                                                                                        )}
                                                                                        {column.title === 'DOING' && (
                                                                                            <span className="doing-icon">🔨</span>
                                                                                        )}
                                                                                        {column.title === 'DONE' && (
                                                                                            <span className="done-icon">✅</span>
                                                                                        )}
                                                                                        <TiDelete style={{ fontSize: "1.4em", cursor:"pointer" }} className={"text-danger"} onClick={() => handleDeleteTask(card, column, categoryName)}/>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                    </tbody>
                                                </table>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade show" id="priorities" role="tabpanel" aria-labelledby="priorities-tab">
                                    <KanbanBoard
                                        title={"PRIORITIES & NON-NEGOTIABLE ACTION LIST"}
                                        categoryName={"priorities_action_list"}
                                        onHandleEditList={saveData}
                                        data={singleTaskingData['priorities_action_list']}
                                    />
                                </div>
                                <div className="tab-pane fade show" id="secondary-action-list" role="tabpanel" aria-labelledby="secondary-action-list-tab">
                                    <KanbanBoard
                                        title={"SECONDARY ACTION LIST"}
                                        categoryName={"secondary_action_list"}
                                        onHandleEditList={saveData}
                                        data={singleTaskingData['secondary_action_list']}
                                    />
                                </div>
                                <div className="tab-pane fade show" id="favorite-action-list" role="tabpanel" aria-labelledby="favorite-action-list-tab">
                                    <KanbanBoard
                                        title={"EASIEST & FAVORITE ACTION LIST"}
                                        categoryName= {"easiest_action_list"}
                                        onHandleEditList={saveData}
                                        data={singleTaskingData['easiest_action_list']}
                                    />
                                </div>
                                <div className="tab-pane fade show" id="clustering-action-list" role="tabpanel" aria-labelledby="clustering-action-list-tab">
                                    <KanbanBoard
                                        title={"CLUSTERING ACTION LIST"}
                                        categoryName={"clustering_action_list"}
                                        onHandleEditList={saveData}
                                        data={singleTaskingData['clustering_action_list']}
                                    />
                                </div>
                                <div className="tab-pane fade show" id="delegating-action-list" role="tabpanel" aria-labelledby="delegating-action-list-tab">
                                    <KanbanBoard
                                        title={"DELEGATING ACTION LIST"}
                                        categoryName={"delegating_action_list"}
                                        onHandleEditList={saveData}
                                        data={singleTaskingData['delegating_action_list']}
                                    />
                                </div>
                            </div>
                        </div> :''
                    }
                </div>
            </div>
        </div>
    );
};

export default SingleTasking;
