import React from 'react';
import HomeNavbar from "./HomeNavbar";
import '../styles/Pricing.css';

const PricingCard = ({ children, featured }) => {
    return (
        <div className={`card h-100 shadow${featured ? ' border-primary' : '-sm'} hover-elevate pricing-card${featured ? ' featured' : ''}`}>
            {[...Array(9)].map((_, i) => (
                <div 
                    key={i} 
                    className="glitter" 
                    style={{
                        left: `${Math.random() * 100}%`,
                        top: `${Math.random() * 100}%`,
                        animationDelay: `${Math.random() * 2}s`
                    }}
                />
            ))}
            {children}
        </div>
    );
};

const Pricing = ({user}) => {
    return (
        <>
            <HomeNavbar user={user} boldItem={"pricing"}/>
            <div className="container py-5">
                <h1 className="display-4 text-center mb-2">Pricing Plans</h1>
                <p className="text-center text-muted mb-5">Choose the perfect plan for your needs. All plans come with a 30-day money-back guarantee.</p>
                <div className="row justify-content-center g-4">
                    <div className="col-md-4">
                        <PricingCard>
                            <div className="card-body p-4">
                                <div className="text-center">
                                    <span className="badge bg-primary text-uppercase mb-3 px-4 py-2">1 Month</span>
                                    <h2 className="display-3 fw-bold mb-0">$20</h2>
                                    <p className="text-muted mb-4">/mo +tx</p>
                                </div>
                                <ul className="list-unstyled">
                                    <li className="d-flex align-items-center mb-3">
                                        <i className="bi bi-check-circle-fill text-primary me-2"></i>
                                        <span>Full Access to All Features</span>
                                    </li>
                                    <li className="d-flex align-items-center mb-3">
                                        <i className="bi bi-check-circle-fill text-primary me-2"></i>
                                        <span>Priority Customer Support</span>
                                    </li>
                                    <li className="d-flex align-items-center mb-3">
                                        <i className="bi bi-check-circle-fill text-primary me-2"></i>
                                        <span>Regular Updates</span>
                                    </li>
                                    <li className="d-flex align-items-center mb-3">
                                        <i className="bi bi-check-circle-fill text-primary me-2"></i>
                                        <span>Cloud Storage Access</span>
                                    </li>
                                </ul>
                                <div className="text-center mt-4">
                                    <button className="btn btn-outline-primary btn-lg px-5">Get Started</button>
                                </div>
                            </div>
                        </PricingCard>
                    </div>
                    
                    <div className="col-md-4">
                        <PricingCard featured>
                            <div className="card-body p-4">
                                <div className="text-center">
                                    <span className="badge bg-primary text-uppercase mb-3 px-4 py-2">3 Months</span>
                                    <h2 className="display-3 fw-bold mb-0">$16</h2>
                                    <p className="text-muted mb-4">/mo +tx</p>
                                </div>
                                <ul className="list-unstyled">
                                    <li className="d-flex align-items-center mb-3">
                                        <i className="bi bi-check-circle-fill text-primary me-2"></i>
                                        <span>Everything in Monthly Plan</span>
                                    </li>
                                    <li className="d-flex align-items-center mb-3">
                                        <i className="bi bi-check-circle-fill text-primary me-2"></i>
                                        <span>20% Cost Savings</span>
                                    </li>
                                    <li className="d-flex align-items-center mb-3">
                                        <i className="bi bi-check-circle-fill text-primary me-2"></i>
                                        <span>Extended Support Hours</span>
                                    </li>
                                    <li className="d-flex align-items-center mb-3">
                                        <i className="bi bi-check-circle-fill text-primary me-2"></i>
                                        <span>Premium Features</span>
                                    </li>
                                </ul>
                                <div className="text-center mt-4">
                                    <button className="btn btn-primary btn-lg px-5">Popular Choice</button>
                                </div>
                            </div>
                        </PricingCard>
                    </div>
                    
                    <div className="col-md-4">
                        <PricingCard>
                            <div className="card-body p-4">
                                <div className="text-center">
                                    <span className="badge bg-primary text-uppercase mb-3 px-4 py-2">1 Year</span>
                                    <h2 className="display-3 fw-bold mb-0">$12</h2>
                                    <p className="text-muted mb-4">/mo +tx</p>
                                </div>
                                <ul className="list-unstyled">
                                    <li className="d-flex align-items-center mb-3">
                                        <i className="bi bi-check-circle-fill text-primary me-2"></i>
                                        <span>Everything in 3-Month Plan</span>
                                    </li>
                                    <li className="d-flex align-items-center mb-3">
                                        <i className="bi bi-check-circle-fill text-primary me-2"></i>
                                        <span>40% Cost Savings</span>
                                    </li>
                                    <li className="d-flex align-items-center mb-3">
                                        <i className="bi bi-check-circle-fill text-primary me-2"></i>
                                        <span>24/7 Premium Support</span>
                                    </li>
                                    <li className="d-flex align-items-center mb-3">
                                        <i className="bi bi-check-circle-fill text-primary me-2"></i>
                                        <span>Early Access Features</span>
                                    </li>
                                </ul>
                                <div className="text-center mt-4">
                                    <button className="btn btn-outline-primary btn-lg px-5">Best Value</button>
                                </div>
                            </div>
                        </PricingCard>
                    </div>
                </div>
                
                <div className="text-center mt-5 py-5 bg-light rounded-3">
                    <h3 className="mb-3">Experience Our Services with a 15-Day Free Trial</h3>
                    <p className="text-muted mb-4 w-75 mx-auto">
                        At OFD, we understand that trying out a new service is a significant decision. 
                        That's why we're excited to offer all new users a 15-day free trial of our premium services. 
                        It's our way of giving you the opportunity to explore everything we have to offer, completely risk-free.
                    </p>
                    <button className="btn btn-primary btn-lg px-5">Start Free Trial</button>
                </div>
            </div>
        </>
    );
};

export default Pricing;
