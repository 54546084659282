import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import firebase from '../firebaseConfig';
import '../styles/Register.css';

function LoginForm() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const validateForm = () => {
        if (!email.trim()) {
            setError('Please enter your email');
            return false;
        }

        if (!password.trim()) {
            setError('Please enter your password');
            return false;
        }

        return true;
    };

    const handleEmailLogin = async (e) => {
        e.preventDefault();
        setError(null);

        if (validateForm()) {
            setIsLoading(true);
            try {
                const { user } = await firebase.auth().signInWithEmailAndPassword(email, password);

                if (user && !user.emailVerified) {
                    setError('Please verify your email before logging in.');
                    return;
                }
                
                navigate('/dashboard');
            } catch (error) {
                console.log('Error logging in:', error);
                if (error.code === 'auth/wrong-password' || 
                    error.code === 'auth/user-not-found' || 
                    error.code === 'auth/invalid-email') {
                    setError('Invalid email or password');
                } else if (error.code === 'auth/too-many-requests') {
                    setError('Too many failed attempts. Please try again later.');
                } else {
                    setError('An error occurred. Please try again.');
                }
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleForgotPassword = async () => {
        if (!email.trim()) {
            setError('Please enter your email address to reset password');
            return;
        }

        setIsLoading(true);
        try {
            await firebase.auth().sendPasswordResetEmail(email);
            setError('Password reset email sent. Please check your inbox.');
        } catch (error) {
            setError('Error sending reset email. Please verify your email address.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="register-container">
            <div className="register-card">
                <div className="register-header">
                    <h1>Welcome Back</h1>
                    <p>Sign in to continue your productivity journey</p>
                </div>

                <form onSubmit={handleEmailLogin} className="register-form">
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter your email"
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter your password"
                            required
                        />
                    </div>

                    {error && <div className="error-message">{error}</div>}

                    <button 
                        type="submit" 
                        className="register-button"
                        disabled={isLoading}
                    >
                        {isLoading ? 'Signing in...' : 'Sign In'}
                    </button>

                    <button 
                        type="button" 
                        className="forgot-password-button" 
                        onClick={handleForgotPassword}
                        disabled={isLoading}
                    >
                        Forgot Password?
                    </button>
                </form>

                <div className="login-link">
                    Don't have an account? <Link to="/register">Create one</Link>
                </div>
            </div>
        </div>
    );
}

export default LoginForm;
