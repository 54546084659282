import React from 'react';
import firebase from '../firebaseConfig';
import { useNavigate } from "react-router-dom";
import { FaRocket, FaChartLine, FaClipboardCheck, FaBrain } from 'react-icons/fa';
import '../styles/Welcome.css';

const Welcome = ({ user }) => {
    const navigate = useNavigate();
    
    const accessDashboard = () => {
        const userId = user.uid;
        const userRef = firebase.firestore().collection('users').doc(userId);
        userRef
            .update({
                tutorialCompleted: true,
            })
            .then(() => {
                navigate('/dashboard')
            })
            .catch((error) => {
                console.error('Error updating tutorial completed status:', error);
            });
    };

    const features = [
        {
            icon: <FaRocket size={30} />,
            title: "Goal Setting",
            description: "Define and track your objectives with our powerful goal-setting framework"
        },
        {
            icon: <FaBrain size={30} />,
            title: "Flow State",
            description: "Learn techniques to achieve and maintain optimal flow state"
        },
        {
            icon: <FaChartLine size={30} />,
            title: "Progress Tracking",
            description: "Monitor your progress with intuitive visualization tools"
        },
        {
            icon: <FaClipboardCheck size={30} />,
            title: "Task Management",
            description: "Organize and prioritize your tasks effectively"
        }
    ];

    return (
        <div className="welcome-container">
            <div className="welcome-header">
                <h1>Welcome to OFD!</h1>
                <p className="welcome-subtitle">
                    Your journey to peak performance and productivity starts here
                </p>
            </div>

            <div className="features-grid">
                {features.map((feature, index) => (
                    <div key={index} className="feature-card">
                        <div className="feature-icon">
                            {feature.icon}
                        </div>
                        <h3>{feature.title}</h3>
                        <p>{feature.description}</p>
                    </div>
                ))}
            </div>

            <div className="getting-started-section">
                <h2>Ready to Begin?</h2>
                <p>
                    We're excited to help you achieve your goals and maximize your potential.
                    Our platform combines cutting-edge productivity techniques with personalized guidance.
                </p>
                <button className="start-button" onClick={accessDashboard}>
                    Start Your Journey
                </button>
            </div>
        </div>
    );
};

export default Welcome;
