import firebase from '../firebaseConfig';
import Goals from "./Dashboard_Tabs/1_Goals";
import SingleTasking from "./Dashboard_Tabs/2_SingleTasking";
import "../styles/dashboard.css"
import FlowTrigger from "./Dashboard_Tabs/3_FlowTrigger";
import ReleaseRecovery from "./Dashboard_Tabs/5_Release&Recovery";
import CausesSymptoms from "./Dashboard_Tabs/10_Causes&Symptoms";
import Logistics from "./Dashboard_Tabs/8_Logistics";
import FlowRoutines from "./Dashboard_Tabs/4_FlowRoutines";
import DistractionsInterruptions from "./Dashboard_Tabs/6_DistractionsInterruptions";
import StickingPlan from "./Dashboard_Tabs/7_StickingPlan";
import TimeManagement from "./Dashboard_Tabs/9_TimeManagement";
import {useEffect, useState} from "react";
import DashBoardNavBar from "./DashboardNavbar";
import { useNavigate } from "react-router-dom";
import NoSubscription from "./NoSubscription";
import ChatBot from "./ChatBot";

function Dashboard({ user }) {
    const [planValidity, setPlanValidity] = useState(0);
    const [userName, setUserName] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const checkPlanValidity = async () => {
            if (user) {
                const userId = user.uid;
                const docRef = firebase.firestore().collection('users').doc(userId);
                const docSnapshot = await docRef.get();
                if (docSnapshot.exists) {
                    const data = docSnapshot.data();
                    setUserName(data.username)
                    const endSubscriptionDate = data.endSubscriptionDate?.toDate();
                    if (endSubscriptionDate > new Date()) {
                        setPlanValidity(2);
                        const tutorialCompleted = data.tutorialCompleted;
                        if(tutorialCompleted === false){
                            navigate('/welcome');
                        }
                    } else {
                        setPlanValidity(1);
                    }
                }
            } else {
                navigate('/login');
            }
            setIsLoading(false);
        };
        checkPlanValidity();
    }, [user, navigate]);

    if (isLoading) {
        return (
            <div className="loading-container">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <div>
            { planValidity === 2 ? (
                <div>
                    <DashBoardNavBar userName={userName} boldItem={'dashboard'}/>
                    <section className="position-relative pt-2">
                        <div className="container mainContainer">
                            <div>
                                <ul className="nav mainTabs" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link active" role="tab" data-bs-toggle="tab" href="#1-goals">
                                            GOALS & VALUES SETTINGS
                                        </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" role="tab" data-bs-toggle="tab" href="#2-single">
                                            SINGLE-TASKING & ACTION LIST
                                        </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" role="tab" data-bs-toggle="tab" href="#3-flowTrigger">
                                            FLOW TRIGGERS
                                        </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" role="tab" data-bs-toggle="tab" href="#5-release">
                                            RELEASE & RECOVERY
                                        </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" role="tab" data-bs-toggle="tab" href="#4-flowRoutine">
                                            FLOW ROUTINES
                                        </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" role="tab" data-bs-toggle="tab" href="#6-distractions">
                                            FLOW DISRUPTORS
                                        </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" role="tab" data-bs-toggle="tab" href="#9-time">
                                            TIME MANAGEMENT
                                        </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" role="tab" data-bs-toggle="tab" href="#8-logistics">
                                            LOGISTICS
                                        </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" role="tab" data-bs-toggle="tab" href="#7-sticking">
                                            COMMITMENT PLAN
                                        </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" role="tab" data-bs-toggle="tab" href="#10-causes">
                                            OVERLOAD BURNOUT PREVENTION
                                        </a>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="1-goals" role="tabpanel">
                                        <Goals user={user}/>
                                    </div>
                                    <div className="tab-pane fade" id="2-single" role="tabpanel">
                                        <SingleTasking user={user}/>
                                    </div>
                                    <div className="tab-pane fade" id="3-flowTrigger" role="tabpanel">
                                        <FlowTrigger user={user}/>
                                    </div>
                                    <div className="tab-pane fade" id="4-flowRoutine" role="tabpanel">
                                        <FlowRoutines user={user}/>
                                    </div>
                                    <div className="tab-pane fade" id="5-release" role="tabpanel">
                                        <ReleaseRecovery user={user}/>
                                    </div>
                                    <div className="tab-pane fade" id="6-distractions" role="tabpanel">
                                        <DistractionsInterruptions user={user}/>
                                    </div>
                                    <div className="tab-pane fade" id="7-sticking" role="tabpanel">
                                        <StickingPlan user={user}/>
                                    </div>
                                    <div className="tab-pane fade" id="8-logistics" role="tabpanel">
                                        <Logistics user={user} />
                                    </div>
                                    <div className="tab-pane fade" id="9-time" role="tabpanel">
                                        <TimeManagement user={user}/>
                                    </div>
                                    <div className="tab-pane fade" id="10-causes" role="tabpanel">
                                        <CausesSymptoms user={user}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <ChatBot />
                </div>
            ) : (
                <NoSubscription/>
            )}
            <div className="mt-5"></div>
        </div>
    );
}

export default Dashboard;